//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-995:_8076,_7342,_4562,_5376,_6640,_7752,_1272,_3608;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-995')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-995', "_8076,_7342,_4562,_5376,_6640,_7752,_1272,_3608");
        }
      }catch (ex) {
        console.error(ex);
      }