
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('div', {
                        'className': 'badge cm_badge cm_badge__' + this.toKebabCase(badge),
                        'key': badge
                    }, '\n      ', badge, '\n    ');
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('product-card', {
                    'handle': this.handle,
                    'class': 'product-card product-card--blends bg-custom text-custom'
                }, _createElement.apply(this, [
                    'div',
                    { 'className': 'product-card__badge-list' },
                    this.out_of_stock ? _createElement('sold-out-badge', {
                        'key': '147',
                        'class': 'badge badge--sold-out'
                    }, 'Sold out') : null,
                    this._badges ? _map(this._badges, repeatBadge1.bind(this)) : null
                ]), _createElement('div', { 'className': 'product-card__figure' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'product-card__image product-card__image--primary aspect-natural',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy',
                    'width': '500',
                    'height': '500'
                }))), _createElement('div', { 'className': 'product-card__info product-card__info--center' }, _createElement('div', { 'className': 'v-stack gap-0.5 w-full justify-items-center' }, _createElement('span', { 'className': 'product-card__title' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'bold'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('price-list', { 'class': 'price-list justify-center' }, _createElement('sale-price', { 'class': 'text-subdued' }, _createElement('span', { 'className': 'sr-only' }, 'Sale price'), this.formatPrice(this.price), '\n        ')), this.wheel_mpn ? _createElement('button', {
                    'className': 'cm_view-on-vehicle-btn button button--outline',
                    'onClick': () => window.Convermax.openVisualizationDialog(this.wheel_mpn),
                    'key': '1253'
                }, '\n        View On Vehicle\n      ') : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []