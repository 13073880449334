import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'genetjin';

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const getItemId = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const variantId = urlParams.get('variant');

  if (variantId) {
    return variantId;
  }

  const sa = window.ShopifyAnalytics;
  if (sa && sa.meta.product.variants.length > 0) {
    return sa.meta.product.variants[0].id;
  }

  return '';
};

const baseInitFunc = shopifyGenericDefaults.InitFunc;
const InitFunc = () => {
  baseInitFunc?.();

  const optionSelectors = window.document.querySelector(
    '.shopify-section--main-product .product variant-picker',
  );

  optionSelectors?.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-option-value')) {
      setTimeout(() => {
        window.Convermax.updateVerifyFitmentWidget(getItemId());
      }, 300);
    }
  });
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  product: {
    ...shopifyGenericDefaults.product,
    getItemId,
  },
  facets: {
    ...shopifyGenericDefaults.facets,
    rangedFacet: [
      ...shopifyGenericDefaults.facets.rangedFacet,
      { fields: ['wheel_center_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['FacetBarWheels', 'FacetBarTires', 'SearchBox'].includes(w.name),
    ),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () => window.document.querySelector('#search-drawer').removeAttribute('open'),
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'AutoSyncVisualization',
      apiKey: AutoSyncKey,
      config: {
        height: '150px',
        vehicleImageSize: 640,
        vehicleAngles: [32],
        showColorPicker: false,
      },
      classModificator: 'search-result',
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
